<script>
import _merge from "lodash/merge";
import finderFieldRange from "@/components/finder-field-range.vue";
import finderFieldPrice from "@/components/finder-field-range-price.vue";
import finderFieldCategory from "@/components/finder-field-type.vue";
import finderFieldCondition from "@/components/finder-field-condition.vue";
import finderFieldAmenities from "@/components/finder-field-amenities.vue";

export default {
  components: {
    finderFieldRange,
    finderFieldPrice,
    finderFieldCategory,
    finderFieldCondition,
    finderFieldAmenities,
  },

  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      newValue: _merge({}, this.value),
    };
  },

  computed: {
    active() {
      return this.$route.name == "finder-filter-generals";
    },
  },

  methods: {
    exitFilters() {
      this.$emit("input", this.value);
    },
    apply() {
      this.$emit("input", this.newValue);
    },
  },

  watch: {
    $route() {
      this.newValue = _merge({}, this.value);
    },
    value: {
      deep: true,
      handler() {
        this.newValue = _merge({}, this.value);
      },
    },
  },
};
</script>

<template>
  <div class="finderMobileFilterView" :class="{ active }">
    <div class="app-bar filters__bar">
      <div class="btn--close">
        <b-button @click="exitFilters">
          <b-icon icon="times" />
        </b-button>
      </div>
      <h4 class="app-bar__title">Filtros</h4>
      <div class="btn--clear">
        <a @click.prevent="resetFilters">
          Limpiar
        </a>
      </div>
    </div>
    <div class="filters__body">
      <div class="widget">
        <b-field label="Tipo de Propiedad">
          <finderFieldCategory v-model="newValue.category" />
        </b-field>
      </div>

      <div class="widget">
        <div class="widget__header">
          <div class="label">Filtrar por precio</div>
        </div>
        <div class="widget__body">
          <finderFieldPrice
            :trade="newValue.trade"
            v-model="newValue.price"
            contained
          />
        </div>
      </div>

      <div class="widget">
        <div class="widget__header">
          <div class="label">Habitaciones</div>
        </div>
        <div class="widget__body">
          <finderFieldRange
            v-model="newValue.ltg_room"
            marks
            contained
            adsorb
          />
        </div>
      </div>

      <div class="widget">
        <div class="widget__header">
          <div class="label">Baños</div>
        </div>
        <div class="widget__body">
          <finderFieldRange
            v-model="newValue.ltg_bath"
            marks
            contained
            adsorb
          />
        </div>
      </div>

      <div class="widget">
        <div class="widget__header">
          <div class="label">Parqueos</div>
        </div>
        <div class="widget__body">
          <finderFieldRange
            v-model="newValue.ltg_park"
            marks
            contained
            adsorb
          />
        </div>
      </div>

      <div class="widget">
        <div class="widget__header">
          <div class="label">Filtrar por condición</div>
        </div>
        <div class="widget__body">
          <finderFieldCondition v-model="newValue.ltg_cond" />
        </div>
      </div>

      <div class="widget">
        <div class="widget__header">
          <div class="label">Filtrar por comodidades</div>
        </div>
        <div class="widget__body">
          <finderFieldAmenities v-model="newValue.amenities" />
        </div>
      </div>
    </div>
    <div class="filters__footer">
      <b-button rounded class="btn--finish" type="is-primary" @click="apply()"
        >Aplicar filtros</b-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filters__body {
  margin-bottom: calc(var(--safe-area-inset-bottom, 0rem) + 4rem);
}
</style>
